<template>
  <div class="d-flex content-center min-vh-100 noto-sans">
    <BContainer>
      <BRow class="justify-content-center">
        <BCol md="9">
          <BCard class="text-left mt-4" footer-bg-variant="light" footer-tag="footer">
            <div slot="header">
              <strong>구독신청 내역</strong>
              <div class="card-header-actions">
                <small class="text-muted">주문 및 결제정보</small>
              </div>
            </div>

            <BRow>
              <BCol v-if="so!==null">

                <BCard border-variant="howcost"
                       class="mb-2"
                       header="howcost"
                       header-bg-variant="howcost"
                       header-text-variant="white">
                  <div slot="header">
                    <strong>주문 정보</strong>
                    <div class="card-header-actions">
                      주문번호: <span class="text-primary font-weight-bold">{{ orderNumber }}</span>
                    </div>
                  </div>

                  <BInputGroup id="orders" class="mb-2">
                    <BInputGroupPrepend is-text>
                      <BIconBagCheckFill class="mr-1"/>
                      상품명
                    </BInputGroupPrepend>
                    <BFormInput :value="so.salesMaster.title" class="font-weight-bold bg-white"
                                readonly type="text"/>
                  </BInputGroup>

                  <BTable hover
                          class="small"
                          :items="so.products"
                          :fields="prdFields"
                          thead-class="text-center"
                          ref="productTable"
                          id="productTable">

                    <template #cell(unit_price)="data">
                      <span v-if="data.item.discount_yn === 'Y'">{{ commify( (data.item.unit_price - data.item.discount_price) )}}</span>
                      <span v-else>{{ commify( data.item.unit_price ) }}</span>
                    </template>

                  </BTable>

                  <BInputGroup class="mb-2">
                    <BInputGroupAppend is-text>
                      <BIconCashStack class="mr-3"/>
                      합계
                    </BInputGroupAppend>
                    <BFormInput v-model="totalPrice" class="text-right font-weight-bold bg-white" readonly/>
                  </BInputGroup>

                  <BInputGroup class="mb-2">
                    <BInputGroupPrepend is-text>
                      <BIconPersonCircle class="mr-1"/>
                      성명
                    </BInputGroupPrepend>
                    <BFormInput v-model="so.name" class="bg-white" readonly/>
                    <BInputGroupAppend is-text>아이디</BInputGroupAppend>
                    <BFormInput v-model="so.userId" class="bg-white" readonly/>
                  </BInputGroup>

                  <BInputGroup id="email" class="mb-2">
                    <BInputGroupPrepend is-text>
                      <BIconAt class="mr-1"/>
                      이메일
                    </BInputGroupPrepend>
                    <BFormInput :value="so.email" class="bg-white" readonly type="email"/>
                  </BInputGroup>

                  <BInputGroup class="mb-2">
                    <BInputGroupPrepend is-text>
                      <BIconTelephone class="mr-1"/>
                      연락처
                    </BInputGroupPrepend>
                    <BFormInput v-model="so.phoneNo" class="bg-white" readonly/>
                  </BInputGroup>
                  <!-- <BInputGroup class="mb-2">
                    <BInputGroupPrepend is-text>
                      <BIconJournal class="mr-3"/>
                      메 모
                    </BInputGroupPrepend>
                    <BFormInput v-model="so.description" class="bg-white" readonly/>
                  </BInputGroup> -->


                  <BInputGroup v-show="false" class="mb-1">
                    <BInputGroupPrepend is-text>
                      <BIconUiChecksGrid class="mr-3"/>
                      구 분
                    </BInputGroupPrepend>
                    <BFormSelect :options="taxStatOpts" :value="so.taxStat" class="bg-white" disabled/>
                  </BInputGroup>
                  <BRow v-if=" so.payStat==='99' ">
                    <BCol class="text-right mt-3">
                      <h4 v-if="discountPrice > 0" class="text-muted" style="font-weight: 600; font-size: 1.25rem;">사전 할인금액: <span class="text-danger">-{{ commify(discountPrice) }} 원</span></h4>
                      <h3 class="font-weight-bold text-muted mb-1">총 결제금액: <span class="text-danger">{{ totalPrice }}</span></h3>
                      <h5 class="small text-muted">(VAT포함)</h5>
                    </BCol>
                  </BRow>

                </BCard>

                <div v-show="so.taxStat==='01'" id="compInfo">
                  <BCard border-variant="howcost"
                         class="m-0"
                         header="howcost"
                         header-bg-variant="howcost"
                         header-text-variant="white">
                    <div slot="header">
                      <strong>사업자 등록 정보</strong>
                      <div class="card-header-actions">
                        <small>계산서 발행을 위한 정보입니다.</small>
                      </div>
                    </div>

                    <BInputGroup class="mb-2">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        회사명
                      </BInputGroupPrepend>
                      <BFormInput v-model="so.compName" autocomplete="comp_name" class="bg-white" readonly
                                  type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-2">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        대표자명
                      </BInputGroupPrepend>
                      <BFormInput v-model="so.prName" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-2">
                      <BInputGroupPrepend is-text>
                        <BIconCardList class="mr-1"/>
                        업태/업종
                      </BInputGroupPrepend>
                      <BFormInput v-model="so.bizKind" class="bg-white" readonly type="text"/>
                    </BInputGroup>


                    <BInputGroup class="mb-2">
                      <BInputGroupPrepend is-text>
                        <BIconBuilding class="mr-1"/>
                        사업자번호
                      </BInputGroupPrepend>
                      <BFormInput v-model="so.compNo" class="bg-white" readonly type="text"/>
                    </BInputGroup>


                    <BInputGroup class="mb-2">
                      <BInputGroupPrepend is-text>
                        <BIconMap class="mr-1"/>
                        주 소
                      </BInputGroupPrepend>
                      <BFormInput v-model="so.address" class="bg-white" readonly/>
                    </BInputGroup>
                  </BCard>
                </div>

                <div v-if="payment!==null" id="payInfo">
                  <BCard border-variant="primary"
                         class="m-0"
                         header="primary"
                         header-bg-variant="primary"
                         header-text-variant="white">
                    <div slot="header">
                      <strong>결제 정보</strong>
                      <div class="card-header-actions">
                        <small>주문제품의 결제 정보입니다</small>
                      </div>
                    </div>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-2"/>
                        결 제 일
                      </BInputGroupPrepend>
                      <BFormInput :value="so.pay_dt" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        결제방식
                      </BInputGroupPrepend>
                      <b-form-input :value="payMethods[payment.PayMethod]" class="bg-white" readonly type="text" />
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        결제금액
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.Amt" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        카드사명
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.fn_name" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        카드번호
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.CardNum" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        할부개월
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.CardQuota" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        승인번호
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.AuthCode" class="bg-white" readonly type="text"/>
                    </BInputGroup>
                    
                    <BInputGroup class="mb-1">
                      <BInputGroupPrepend is-text>
                        <BIconCreditCard2Front class="mr-1"/>
                        결제결과
                      </BInputGroupPrepend>
                      <BFormInput :value="payment.ResultMsg" class="bg-white" readonly type="text"/>
                    </BInputGroup>

                  </BCard>
                </div>

                <BRow v-if=" so.payStat==='99' " class="py-3 m-0 mt-4 mb-3" style="border-top: solid 1px rgba(175, 175, 175, 0.641); border-bottom: solid 1px rgba(175, 175, 175, 0.641);">
                  <BCol align-self="end" class="text-right">
                    <BFormCheckbox class="mt-1 display-in" id="agreeTerms" v-model="so.agreement" name="agreeTerms" value="Y" unchecked-value="N">
                      <span class="text-center noto-sans" style="font-size: 11pt; font-weight: 500;">약관을 동의 합니다 (필수)</span>
                    </BFormCheckbox>
                    <b-icon class="ml-1 c-pointer text-right" icon="caret-right-fill" v-b-modal.termsModal />
                  </BCol>
                </BRow>

                <BRow v-if=" so.payStat==='00' " class="mt-1">
                  <BCol class="text-center">
                    <BButton class="mt-2 mb-2" variant="info" @click="gotoXcostMe">홈페이지로 이동</BButton>
                  </BCol>
                </BRow>

              </BCol>
            </BRow>
            <BRow v-if="so!==null">
              <BCol>
                <li v-show="so.payStat==='00'" class="list-group-item bg-dark text-white mt-2 mt-0">
                  <strong>결제 취소 및 구매 철회 안내</strong><br/>
                  <span class="text-muted">
                      결제 후 15일 이내 구매를 취소 할 수 있으며, 제품키 및 사용권한 등은 <u>취소시 즉시 삭제</u>됩니다.<br/>
                      <b>단, 제품키를 인증하였거나 유료서비스를 이용한 경우에는 구매 철회를 할 수 없습니다.</b><br/>
                      구매 철회시 <B><u>주문번호 및 취소사유를 기재</u></B>하여 이메일(<b>mycost@naver.com</b>)로 보내주시기 바랍니다..<br/>
                      담당자 확인 후 취소까지 최대 14일이 소요될 수 있습니다.
                  </span>
                </li>
              </BCol>
            </BRow>


            <div v-if="so!==null">

            <BRow v-show="so.payStat!=='00'" class="mt-2">
              <BCol class="text-center">

                <BButton v-show="isBoucherOrder"
                         @click="payWithBoucher"
                         variant="primary">
                  <BIconCash/> 바우처 신청 확인
                </BButton>

                <BButton type="button" variant="primary"
                         v-show="!isBoucherOrder"
                         :disabled="isSubmiting||isCardSubmiting"
                         @click="payWithCash">
                  <BSpinner v-show="isSubmiting" class="mr-1" small></BSpinner>
                  <BIconCash/>
                  <strong> 현금 결제</strong>
                </BButton>

                <BButton type="button" variant="warning"
                         v-show="!isBoucherOrder"
                        :disabled="isSubmiting||isCardSubmiting"
                         class="ml-2"
                         @click="payWithCreditCard">
                  <BSpinner v-show="isCardSubmiting" class="mr-1" small></BSpinner>
                  <BIconCreditCardFill/>
                  <strong> 카드 결제</strong>
                </BButton>

              </BCol>
              <BCol class="text-center">
                <BButton :disabled="!isCardSubmiting" type="button" variant="danger" @click="resetButton">
                  <strong>Reset</strong>
                </BButton>

                <BButton v-show=" so.payStat==='99' " class="ml-2" type="button" variant="danger" @click="cancelButton" :disabled="isCardSubmiting">
                  <strong>주문취소</strong>
                </BButton>
              </BCol>
            </BRow>
            </div>

            <!-- <div slot="footer">
              <div class="small text-muted">
                <strong><i class="fa fa-copyright"></i> <a href='http://qqkorea.net'>(주)하우코스트</a></strong>
                <span class="hidden-xs en">All rights reserved.</span><br>
                <span class="hidden-xs en">
                  대표자 : 유현오 | E-Mail : <a href="mailto:mycost@naver.com">mycost@naver.com</a>) <br>
                  <a href='http://xcost.me/bbs/qalist.php'>적산문의/프로그램/문의 1:1상담</a> | tel. 031-417-0466 | fax. 02-6442-0466 <br/>
                  사무실 : 경기도 수원시 권선구 수성로 8 경기중소기업성장지원센터 303호 <br/>부설연구소 : 건설계약연구소/벤쳐기업인증/연구개발서비스업등록/소프트웨어개발업/ISO9001,ISO14001인증<br/>
                  사업자번호 : 809-86-00250</span>
              </div>
            </div> -->

          </BCard>


        </BCol>
      </BRow>
    </BContainer>

    <b-modal id="termsModal" size="xl" title="이용약관">
      <BFormTextarea
          v-model="termsText"
          disabled
          max-rows="30"
          rows="30"
      ></BFormTextarea>
    </b-modal>

  </div>
</template>

<script>
import {
  apiCall,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  commify, alertConfirm,
  warningAlertPageMove,
  warpSite,
} from '../../../common/utils';
import {
    PayStatOpts, 
} from '../../../common/salesType';
import terms from "../user-order/_terms";
import DaumPostcode from 'vuejs-daum-postcode';
import moment from "moment";

const _order = {
  prd_name: '',
  sales_code : null,
  sales_id: null,
  disp_name: '',
  prd_code: null,
  dept_code: null,
  unit_price: 0,
  qty: 0,
  warranty_mon: null,
  price: 0,
  pay_amt: null,
  pay_type: null,
  cu_seq: null,
  cu_id: null,
  ord_dt: null,
  email: null,
  name: null,
  phone_no: null,
  comp_no: null,
  comp_name: null,
  pr_name: null,
  address: null,
  zip_code: null,
  appr_yn: null,
  appr_dt: null,
  send_stat: null,
  send_dt: null,
  pay_stat: null,
  pay_dt: null,
  tax_stat: null,
  tax_dt: null,
  tax_seq: null,
  biz_kind: null,
  cncl_yn: null,
  cncl_dt: null,
  cncl_desc: null,
  description: '',
  reg_dt: null,
  upd_dt: null,
  reg_id: null,
  upd_id: null,
  agreement: null,
  auth_no: null,
  addr_detail: '',
  auth_seq: null,
  trs_no: null,
  cncl_no: null,
};


export default {
  name: 'MultiOrderDetail',
  components: {
    DaumPostcode
  },
  props: ['orderToken'],
  data() {
    return {
      isBoucherOrder: false,
      orderNumber: null,
      prdCode: '2106-0002',
      so: null,
      order: cloneVar(_order),
      isEmailChecked: false,
      termsText: terms[0].text,
      taxStatOpts: [
        {value: null, text: '개인/사업자 선택'},
        {value: '03', text: '개인'},
        {value: '01', text: '사업자'},
      ],
      isSubmiting: false,
      isCardSubmiting: false,
      isCanceling: false,
      isEmailSending: false,
      prdInfo: null,
      imgSrc: null,
      unitPrice: null,
      totalPrice: 0,
      orderEnabled: false,
      prdCodeOpts: [
        {text: '2개(1+1)', value: '2106-0002'},
        {text: '1개', value: '2106-0001'},
      ],
      payTypeOpts: {
        '9999': '주문생성',
        '0000': '결제대기',
        '1000': '현금(계좌)',
        '0001': '쿠폰',
        '0010': '포인트',
        '0011': '포인트|쿠폰',
        '0100': '카드',
        '0101': '카드|쿠폰',
        '0110': '카드|포인트',
        '0111': '카드|포인트|쿠폰',
        '2000': '후불',
        '1001': '현금|쿠폰',
        '1010': '현금|포인트',
        '1011': '현금|포인트|쿠폰',
        '1100': '현금|카드',
        '1101': '현금|카드|쿠폰',
        '1110': '현금|카드|포인트',
        '1111': '현금|카드|포인트|쿠폰',
      },
      payMethods: {
        'CARD': '신용카드',
        'BANK': '계좌이체',
        'VBANK': '가상계좌',
        'CELLPHONE': '휴대폰',
        'CLGIFT': '문화상품권',
        'CASHRCPT': '현금영수증',
        'KAKAO': '카카오',
        'NAVER': '네이버'
      },
      payment: null,
      prdFields: [
        {key:'prd_code', label: '제품코드' },
        {key:'disp_name', label: '제품명' },
        {key:'warranty_mon', label: '사용기간 (월)', tdClass: 'text-right mr-2' },
        {key:'unit_price', label: '금액 (VAT포함)', tdClass: 'text-right mr-2', formatter: v=>{  return commify(v)}},
      ],
      isModalTerms: false,
      discountPrice: 0,
    }
  },
  async created() {
    try {
      // await this.getPrdInfo();
      await this.getSalesOrderInfo();
      this.unitPrice = commify(this.so.amount) + ' 원';
      this.totalPrice = commify(this.so.amount) + ' 원';
      
      // console.log("this.so ==================> ", this.so);
      // console.log("this.so.products ==================> ", this.so.products);ㅋ

      await this.salesProductVerify();
      
      this.so.products.forEach( item => {
        if (item.discount_yn === 'Y') this.discountPrice += item.discount_price;
      });
    }catch(err) {
      console.log(err);
    }
  },
  beforeMount() {

  },
  mounted() {
    console.log("-------- mounted: OrderForm ----- prdCode : " + this.prdCode);

  },
  methods: {
    commify(num) { return commify(num) },
    addressModalShow() {
      this.$bvModal.show("addressModal");
    },
    calcPrice() {
      this.totalPrice = commify(this.so.price) + ' 원';
    },
    gotoXcostMe() {
      window.location.href = warpSite();
    },

    resetButton() {
      this.isCardSubmiting = false;
    },

    async cancelButton() {
      const ret = await alertConfirm(
          this.$bvModal,
          `주문을 취소하시겠습니까?`,
          '주문 취소 확인'
      );
      if( !ret ) return;

      console.log("getOrderInfo ---> ", this.orderToken);
      try {
        const r = await apiCall('post', `/api/sales/cancel`, {orderToken: this.orderToken});
        if (r.code===200) {
          await alertWarn(this.$bvModal, '주문이 취소 되었습니다', '주문정보 취소 완료');
          window.location.href = warpSite();
        }else{
          await alertWarn(this.$bvModal, '주문정보 링크가 만료되었습니다', '주문정보 없음');
          return;
        }

      } catch (err) {
        console.log(err);
      }

    },

    async getSalesOrderInfo() {
      console.log("getOrderInfo ---> ", this.orderToken);
      try {
        const r = await apiCall('post', `/api/sales/info`, {orderToken: this.orderToken});
        if (r.code===200) {

          if( r.result.cancelYn==='Y'){
            await alertWarn(this.$bvModal, '주문 정보가 없거나 취소된 주문입니다.', '주문정보 없음');
            window.location.href = warpSite();
            return;
          }

          this.so = r.result;
          this.so['agreement'] = 'N';
          this.orderNumber = this.so._id.toUpperCase();

          let title = r.result.salesMaster.title;
          console.log( r.result )
          if( title.indexOf('바우처') > -1){
            console.log( '바우처 오더 입니다.')
            this.isBoucherOrder = true;
          }

          if (this.so.payStat === '00' && this.so.payment) {
            await this.getPaymentInfo(this.order.trsNo);
          }

        }else{
          await alertWarn(this.$bvModal, '주문정보 링크가 만료되었습니다', '주문정보 없음');
          return;
        }
      } catch (err) {
        console.log(err);
      }


    },

    async salesProductVerify() {
      if (this.so.payStat !== '99') {
        return ;
      }

      try {
        const r = await apiCall('POST', `/api/product/verify`, this.so);
        if (r.code === 200) {
          return r;
        } else {
          throw r;
        }
      } catch (error) {
        console.log(error);
        await warningAlertPageMove( this.$bvModal, error.message, '주문확인', '#/' );
      }
    },

    async selectPrdCode() {
      await this.getPrdInfo();

      try {
        if (this.prdCode === '2106-0002') {
          this.order.qty = 2;
        } else {
          this.order.qty = 1;
        }
        this.order.price = this.prdInfo.unit_price * this.order.qty;
        this.unitPrice = this.prdInfo.unit_price;
        this.totalPrice = commify(this.order.price) + ' 원';
      } catch (err) {
        console.log(err);
      }

    },


    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

    async getPrdInfo() {
      console.log("getPrdInfo ---- " + this.prdCode);

      try {
        const r = await apiCall('get', `/api/order/prd-info/${this.prdCode}`);
        if (r.result) {
          this.prdInfo = r.result[0];
          this.imgSrc = $baseURL + "/images/" + this.prdInfo.file_nm;
          //console.log( this.prdInfo );
          //console.log( this.imgSrc );
        }
      } catch (err) {
        console.log(err);
      }
    },

    async getPaymentInfo(trsNo) {
      console.log("getPaymentInfo ---- " + trsNo);

      try {
        const param = {orderToken: this.orderToken, trsNo: trsNo};
        const r = await apiCall('post', `/api/payment/info`, param);

        if (r.result) {
          this.payment = r.result[0];
          //console.log( this.prdInfo );
          //console.log( this.imgSrc );
        }
      } catch (err) {
        console.log(err);
      }
    },

    async payWithBoucher(){

      console.log("-------payWithBoucher---------");

      try {

        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        const ret = await alertConfirm(
            this.$bvModal,
            `결제 안내 메일을 발송합니다. 바우처신청을 진행 하시겠습니까? `,
            '바우처 결제 확인'
        );
        if( !ret ) return;

        this.isSubmiting = true;

        const r = await apiCall('post', `/api/payment/sales/boucher`, {orderToken: this.orderToken});
        // const r = await apiCall('post', `/api/order/confirm`, this.order);
        //console.log( "onSubmit ----------------> result: ", r );
        if (r.code === 200) {
          await alertSuccess(this.$bvModal, '성공적으로 처리 되었습니다. 주문 내용 및 결제 방법은 이메일을 확인하시기 바랍니다.');
          window.location.href = warpSite();
          return;
        } else {
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        this.isSubmiting = false;

      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmiting = false;
      }

    },

    async payWithCash() {
      console.log("-------payWithCash---------");

      try {

        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        const ret = await alertConfirm(
            this.$bvModal,
            `결제 안내 메일을 발송합니다. 현금결제/계좌이체를 진행 하시겠습니까? `,
            '현금결제/계좌이체 확인'
        );
        if( !ret ) return;

        this.isSubmiting = true;

        const r = await apiCall('post', `/api/payment/sales/cash`, {orderToken: this.orderToken});
        // const r = await apiCall('post', `/api/order/confirm`, this.order);
        //console.log( "onSubmit ----------------> result: ", r );
        if (r.code === 200) {
          await alertSuccess(this.$bvModal, '성공적으로 처리 되었습니다. 주문 내용 및 결제 방법은 이메일을 확인하시기 바랍니다.');
          window.location.href = warpSite();
          return;
        } else {
          await alertError(this.$bvModal, `[ERROR] ${r.message}`);
        }
        this.isSubmiting = false;

      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmiting = false;
      }

    },


    async payWithCreditCard() {
      console.log("-------- payWithCreditCard -------- ");

      try {
        if (this.so.agreement !== "Y") {
          await alertWarn(this.$bvModal, "약관 동의가 필요합니다", "약관 동의 확인");
          return;
        }

        this.isCardSubmiting = true;
        const r = await apiCall('post', `/api/payment/sales/gateway`, {orderToken: this.orderToken});
        if (r.code === 200) {
          const rr = r.result;
          await this.submitPayWindow(rr.pgData, rr.ret);

        } else {
          alert(r.message);
        }
        location.reload();
        
      } catch (err) {
        alert(err.message);
        console.log("error on payWithCreditCard", err);
      }

    },

    async submitPayWindow(pgData, obj) {
      const frm = document.createElement('form');
      frm.setAttribute('charset', "utf-8");
      frm.setAttribute('accept-charset', "utf-8");
      frm.setAttribute('name', 'tranMgr');
      frm.setAttribute('action', obj.ActionURL);
      frm.setAttribute('target', 'payWindow');
      Object.keys(pgData).forEach(k => {
        const hField = document.createElement("input");
        hField.setAttribute('type', 'hidden');
        hField.setAttribute('name', k);
        hField.setAttribute('value', pgData[k]);
        frm.appendChild(hField);
      });
      console.log('pgData --->', pgData);

      document.body.appendChild(frm);
      const popupX = (window.screen.width / 2) - (545 / 2);
      const popupY = (window.screen.height / 2) - (573 / 2);
      const winopts = "width=545,height=573,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=no,left=" + popupX + ", top=" + popupY + ", screenX=" + popupX + ", screenY= " + popupY;
      const win = window.open("", "payWindow", winopts);

      try {
        if (win == null || win.closed || typeof win.closed == 'undefined' || win.screenLeft == 0) {
          alert('브라우저 팝업이 차단으로 설정되었습니다.\n 팝업 차단 해제를 설정해 주시기 바랍니다.');
          return false;
        }
      } catch (e){
        console.log(e);
        return false;
      }

      frm.submit();
    },

    resetForm() {
      this.order = cloneVar(_order);
      this.isEmailChecked = false;

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },

  },

}
</script>
